import type { IResponse, IUser, IUserData } from "./interfaces/IUser";
import Cookies from 'js-cookie';

export async function registerUser(userData: IUserData): Promise<IResponse> {
  let responseData: IResponse = createResponse(null, 0, '');

  await useFetch('https://preprod.api.recup-plast.fr/api/authentication/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
    onResponseError({ response }) {
      responseData = createResponse(response._data, response.status, response.statusText);
    },
    onResponse({ response }) {
      responseData = createResponse(response._data, response.status, response.statusText);
    }
  });
  return responseData;
}

export async function loginUser(userData: IUser): Promise<IResponse> {
  let responseData: IResponse = createResponse(null, 0, '');
  await useFetch('https://preprod.api.recup-plast.fr/api/authentication/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),

    onResponseError({ response }) {
      responseData = createResponse(response._data, response.status, response.statusText);
    },
    onResponse({ response }) {
      const data = response._data;
      if (data && data.content && data.content.access_token) {
        Cookies.set('token', data.content.access_token);
      }
      responseData = createResponse(null, response.status, "Token non trouvé dans la réponse.");
    }
  });
  return responseData;
}

export async function getProfile(): Promise<IResponse> {
  let token = Cookies.get('token');
  if (!token) {
    return createResponse(null, 401, 'Token non trouvé.');
  }

  try {
    const response = await fetch('https://preprod.api.recup-plast.fr/api/recup-plast/information/me', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      }
    });

    if (!response.ok) {
      return createResponse(null, response.status, response.statusText);
    }

    const data = await response.json();
    return createResponse(data, response.status, "Token trouvé dans la réponse.");

  } catch (error) {
    return createResponse(null, 500, "error in catch");
  }
}

export async function putProfile(bodyJSON: any): Promise<IResponse> {
  let token = Cookies.get('token');
  if (!token) {
    return createResponse(null, 401, 'Token non trouvé.');
  }
  try {
    const response = await fetch('https://preprod.api.recup-plast.fr/api/recup-plast/information/me', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      },
      body: bodyJSON
    });

    if (!response.ok) {
      return createResponse(null, response.status, response.statusText);
    }

    const data = await response.json();
    return createResponse(data, response.status, "Mise à jour réussie.");
  } catch (error) {

    return createResponse(null, 500, "Erreur lors de la mise à jour");
  }
}

export async function deleteProfile(): Promise<IResponse> {
  let token = Cookies.get('token');
  if (!token) {
    return createResponse(null, 401, 'Token non trouvé.');
  }

  let bodyJSON = JSON.stringify(token);
  try {
    const response = await fetch('https://preprod.api.recup-plast.fr/api/authentication/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      },
      body: bodyJSON
    });

    if (!response.ok) {
      return createResponse(null, response.status, response.statusText);
    }
    return createResponse(null, response.status, "Token trouvé dans la réponse.");

  } catch (error) {
    return createResponse(null, 500, "error in catch");
  }
}

export function createResponse(data: any, code: number, message: string): IResponse {
  return {
    _data: data,
    code: code,
    message: message,
  };
}